import React, { useEffect, useState , useRef} from "react";
import { useNavigate, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from './CarouselSlider';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import eventsData from './Events.json'; 

function Events(props) {
  let navigate = useNavigate();
  let { year_param } = useParams();
  const [events, setEvents] = useState([]);
  const [year, setYear] = useState(year_param || new Date().getFullYear());

  useEffect(() => {
    getEvents(year_param);
    setYear(year_param);
  }, [year_param]);

  const slides = [
    ...events,
  ];

  const getEvents = async (year) => {
    props.loderFunction(true);
    try {
      const res = {};
      let data = [];
      data = eventsData.filter(event => event.year === year);
      if (data.length === 0) {       
        navigate('/timeline');  
      }
      const dataWithImages = data.map(event => ({
        ...event,
        image: Array.isArray(event.image)  
           ?`${process.env.PUBLIC_URL}${event.image[0]}`
          : `${process.env.PUBLIC_URL}${event.image}` 
      }));
      res.data = dataWithImages;
      setEvents(res.data);
    } catch (err) {
      props.loderFunction(false);
    }
    props.loderFunction(false);
  };

  const handleSelect = (e) => {
    navigate({
      pathname: "/timeline/" + e,
    });
    setYear(e);
    setEvents([]);
    getEvents(e);
  };

  return (
    <div className="bk-slider">
      <DropdownButton id="dropdown-basic-button" title={year} variant="secondary" onSelect={handleSelect}>
        <Dropdown.Item eventKey="2014-2015">2014-2015</Dropdown.Item>
        <Dropdown.Item eventKey="2015-2016">2015-2016</Dropdown.Item>
        <Dropdown.Item eventKey="2022-2023">2022-2023</Dropdown.Item>
        <Dropdown.Item eventKey="2023-2024">2023-2024</Dropdown.Item>
        <Dropdown.Item eventKey="2024-2025">2024-2025</Dropdown.Item>
      </DropdownButton>
      <CarouselSlider images={slides.map((event) => event)} clickable={true}/>
      <section className="mt-5"></section>
    </div>
  );
};

export default Events;
