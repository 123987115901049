import React from "react";
import images from "../inc/GalleryImages";

function Gallery() {
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center gy-4 gx-4">
        <h2 className="text-center">Gallery</h2>
        {images.map((item) => {
          return (
            <div className="col-md-4">
              <img src={item} className="img-fluid rounded" alt=""></img>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Gallery;
